// 保存
export const setStorage = (name, value) => {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    localStorage.setItem(name, value)
}
// 获取普通类型
export const getItem = name => {
    return localStorage.getItem(name)
}
// 获取对象
export const getStorage = name => {
    const data = localStorage.getItem(name)
    try {
        return JSON.parse(data)
    } catch (err) {
        return data
    }
}
// 删除
export const delStorage = name => {
    localStorage.removeItem(name)
}
// 清空
export const clrStorage = () => {
    localStorage.clear()
}
